.ThreeDImageContainer {
    position: relative;
    width: 100%;
    height: 100vh;
   
  }
  
  .ThreeDImageCanvas {
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  