/* Remove the default shadow and add underline effect */
.nav-link {
  text-decoration: none;
  background-color: transparent; /* Remove background color */
  border: none; /* Remove border */
  box-shadow: none; /* Remove box-shadow */
  transition: none; /* Remove any transition effects */
  font-weight: bold;
  text-transform: uppercase;
}

/* Add underline on hover */
.nav-link:hover {
  text-decoration: underline;
  background-color: transparent; /* Maintain transparent background on hover */
  border: none; /* Maintain no border on hover */
  box-shadow: none; /* Maintain no box-shadow on hover */
}

/* Remove box-shadow and outline on Link focus and active */
.nav-link:focus,
.nav-link:active,
.no-shadow {
  outline: none;
  box-shadow: none;
}

/* Style for the theme selector */
.theme-selector {
  /* Add your theme selector styling here */
}

/* Style for the navigation title */
.nav-title {
  /* Add your navigation title styling here */
}