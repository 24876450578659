.testimonial-section {
    background-image: url("https://cdn.expresshealthcare.in/wp-content/uploads/2020/01/22122412/Doctors-consultation.jpg"); /* Replace with the URL of your background image */
    background-size: cover;
    background-attachment: fixed;
    padding: 100px 0; /* Adjust padding as needed */
    color: white; /* Set text color to make it visible against the background */
  }
  
  .google-bg {
    background-image: url('https://www.schemecolor.com/wallpaper?i=455&desktop');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }